import React, { useState, useEffect } from 'react';
import api from 'services/api';
import config from 'config';
import { validadeEmail } from '../Validations/Validations';
import { phoneMask, onlyNumbers } from '../ToNormalize/ToNormalize';
import { Modal } from 'react-bootstrap';
import CustomButton from 'client/components/CustomButton/CustomButton.jsx';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastr } from 'react-redux-toastr';
import { faDownload, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import './styles.css';

const ShareNFe = (props) => {
  const [shareOption, setShareOption] = useState(0);
  const [loadingSendMail, setLoadingSendMail] = useState(false);
  const [loadingDownloadXML, setLoadingDownloadXML] = useState(false);
  const [clientSelectedNumber, setClientSelectedNumber] = useState('');
  const [clientSelectedMail, setClientSelectedMail] = useState('');
  const [link, setLink] = useState('');

  const options = [
    {
      key: 'WhatsApp',
      icon: (
        <FontAwesomeIcon
          icon={faWhatsapp}
          className="whatsAppIcon"
          aria-hidden="false"
        />
      ),
      text: <span id="optionText">Compartilhar via WhatsApp</span>,
      onClick: () => setShareOption(1),
    },
    {
      key: 'E-mail',
      icon: (
        <FontAwesomeIcon
          icon={faEnvelope}
          className="mailIcon"
          aria-hidden="false"
        />
      ),
      text: <span id="optionText">Compartilhar via E-mail</span>,
      onClick: () => setShareOption(2),
    },
    {
      key: 'XML',
      icon: (
        <FontAwesomeIcon
          icon={faDownload}
          className="mailIcon"
          aria-hidden="false"
          title="Baixar XML"
        />
      ),
      text: (
        <>
          <span id="optionText">
            Download XML &nbsp;
            {loadingDownloadXML && <span class="fa fa-spinner fa-pulse"></span>}
          </span>
        </>
      ),
      onClick: () => handleXMLDownloadButton(),
    },
  ];

  useEffect(() => {
    loadLink(props.nfe);
    setClientSelectedNumber(
      props.nfe.Customer.Phone || props.nfe.Provider.Phone || ''
    );
    setClientSelectedMail(
      props.nfe.Customer.Email || props.nfe.Provider.Email || ''
    );
  }, []);

  const loadLink = (nfe) => {
    setLink(nfe.docPDFDownload);
  };

  /* const loadClientSelected = async (customerId) => {
    if (!customerId)
      return console.log(
        'Ocorreu algum problema ao carregar o Cliente da Venda'
      );
    const response = await api.get(
      config.endpoint + `customer/byid/${customerId}`
    );
    if (response.data.status) {

    } else {
      return console.log(
        'Ocorreu algum problema ao carregar o Cliente da Venda'
      );
    }
  }; */

  /* const getCustomerId = async () => {
    setLoading(true);
      try {
        const customerId = await getById(props.nfe.saleId)

        return;
      } catch (err) {
        console.error(err);
      }
    finally {
      setLoading(false);
    }
  } */

  const renderModalTitle = (option) => {
    switch (option) {
      case 0:
        return 'Opções de compartilhamento';
      case 1:
        return 'Compartilhar via WhatsApp';
      case 2:
        return 'Compartilhar via E-mail';
      default:
        return 'Opções de compartilhamento';
    }
  };

  const renderModalBody = (option) => {
    switch (option) {
      case 0:
        return indexBody();
      case 1:
        return whatsappOption();
      case 2:
        return emailOption();
      default:
        return indexBody();
    }
  };

  const renderModalFooter = (option) => {
    if (option === 0) return indexFooter();
    return optionFooter();
  };

  const indexFooter = () => {
    return (
      <CustomButton
        style={{ padding: '5px', fontWeight: 'normal', fontSize: '15px' }}
        onClick={() => props.onHide()}
        type="button"
        bsStyle="danger"
        pullRight
        fill
      >
        Cancelar
      </CustomButton>
    );
  };

  const optionFooter = () => {
    return (
      <div>
        <CustomButton
          style={{
            padding: '5px',
            fontWeight: 'normal',
            fontSize: '15px',
            marginLeft: '5px',
          }}
          onClick={() => handleSubmit()}
          disabled={!!loadingSendMail}
          type="button"
          id="btn-salvar"
          pullRight
          fill
        >
          Enviar{' '}
          <span
            className={loadingSendMail ? 'fa fa-spinner fa-pulse fa-1x' : ''}
          />
        </CustomButton>
        <CustomButton
          style={{
            marginTop: '5px',
            padding: '5px',
            fontWeight: 'normal',
            fontSize: '15px',
          }}
          onClick={() => handleClose()}
          disabled={!!loadingSendMail}
          type="button"
          id="cancelar"
          pullRight
          fill
        >
          Cancelar
        </CustomButton>
      </div>
    );
  };

  const indexBody = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {options.map((op) => {
          return (
            <div
              key={op.key}
              className="cardOption"
              id={op.key}
              onClick={op.onClick}
            >
              <span style={{ width: '80px' }}>{op.icon}</span>
              {op.text}
            </div>
          );
        })}
      </div>
    );
  };

  const whatsappOption = () => {
    return (
      <div>
        <div>
          <label>Nome</label>
          {!!props.nfe.Customer && props.nfe.Customer.Company_Name ? (
            <input
              className="form-control foco-input"
              value={
                props.nfe.Customer.Company_Name ?? 'Cliente não encontrado'
              }
              disabled={true}
            />
          ) : (
            <input
              className="form-control foco-input"
              value={
                props.nfe.Provider.companyName ?? 'Fornecedor não encontrado'
              }
              disabled={true}
            />
          )}
        </div>
        <div>
          <label>Celular</label>
          <input
            className="form-control foco-input"
            value={phoneMask(clientSelectedNumber)}
            onKeyDown={(e) => (e.key == 'Enter' ? handleSubmit() : null)}
            onChange={(event) =>
              setClientSelectedNumber(onlyNumbers(event.target.value))
            }
          />
        </div>
      </div>
    );
  };

  const emailOption = () => {
    return (
      <div>
        <div>
          <label>Nome</label>
          {!!props.nfe.Customer && props.nfe.Customer.Company_Name ? (
            <input
              className="form-control foco-input"
              value={
                props.nfe.Customer.Company_Name ?? 'Cliente não encontrado'
              }
              disabled={true}
            />
          ) : (
            <input
              className="form-control foco-input"
              value={
                props.nfe.Provider.companyName ?? 'Fornecedor não encontrado'
              }
              disabled={true}
            />
          )}
        </div>
        <div>
          <label>E-mail</label>
          <input
            className="form-control foco-input"
            value={clientSelectedMail}
            onChange={(event) =>
              setClientSelectedMail(event.target.value.trim())
            }
            onKeyDown={(e) => (e.key == 'Enter' ? handleSubmit() : null)}
          />
        </div>
      </div>
    );
  };

  const handleSubmit = async () => {
    switch (shareOption) {
      case 1:
        if (clientSelectedNumber.length <= 9)
          toastr.warning('Telefone inválido');
        else {
          const message = `Prezado(a) cliente, Informamos que sua Nota Fiscal Eletrônica (NF-e) foi emitida com sucesso. \nVocê pode fazer o download do documento XML através do seguinte link: ${props.nfe.docXMLDownload}`;
          const encodedMessage = encodeURIComponent(message);
          const url = `${config.whatsappendpoint}${clientSelectedNumber}?text=${encodedMessage}`;

          await window.open(url, '_blank');

          setShareOption(0);
          props.onSubmit();
        }
        break;
      case 2:
        await handleMail();
        break;
      default:
        break;
    }
  };

  const handleXMLDownloadButton = async () => {
    setLoadingDownloadXML(true);
    try {
      const xmlUrl = props.nfe.docXMLDownload;
      if (xmlUrl) {
        window.open(xmlUrl, '_blank');
      } else {
        toastr.error('URL do XML não encontrada');
      }
    } catch (e) {
      toastr.error(e);
    } finally {
      setLoadingDownloadXML(false);
    }
  };

  const handleMail = async () => {
    // alterar aqui para enviar os dados necessarios
    if (!validadeEmail(clientSelectedMail))
      return toastr.warning('E-mail inválido');

    setLoadingSendMail(true);

    try {
      const response = await api.post(
        config.endpoint + `email/send-nfe/${props.nfe.id}`,
        {
          Email: clientSelectedMail,
        }
      );

      toastr.success(response.data.message);
      return props.onSubmit();
    } catch (err) {
      toastr.error(
        'Falha ao enviar email',
        'Identificamos uma falha ao enviar email. Tente novamente mais tarde e caso persista, acione o suporte técnico online'
      );
    }

    setLoadingSendMail(false);
  };

  const handleClose = () => {
    setShareOption(0);
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={() => handleClose()}
      animation={props.animation}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>{renderModalTitle(shareOption)}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderModalBody(shareOption)}</Modal.Body>
      <Modal.Footer>{renderModalFooter(shareOption)}</Modal.Footer>
    </Modal>
  );
};

export default ShareNFe;
