import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, change } from 'redux-form';

import Input from '../../../views/Clients/NewClient/FormClient/components/Input';

import { Modal, Col } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import productsRepository from '../../../../repositories/Products';
import providerRepository from '../../../../repositories/Providers';

import useDebounce from 'hooks/useDebounce';

function AssociateProduct({ onHide, onCancel, onSubmit, itemToEdit }) {
  const [products, setProducts] = useState([]);
  const [query, setQuery] = useState('');
  const [queryDebounced, setQueryDebounced] = useState('');
  const [loading, setLoading] = useState(false);

  const companyId = localStorage.getItem('ID_EMPRESA');
  const dispatch = useDispatch();
  const { itemToAssociate, items, providerInDB } = useSelector(
    (state) => state.form.PurchaseXmlImport.values
  );

  const debouncedSearchQuery = useDebounce(setQueryDebounced, 800);

  useEffect(() => {
    getProducts();
  }, [queryDebounced]);

  const getProducts = async () => {
    try {
      const { data: products } = await productsRepository.getAllPaginated({
        Company_id: companyId,
        page: 1,
        notBarCode: true,
        limit: 20,
        query,
        IsActive: '1',
      });

      setProducts(products);
    } catch (err) {
      console.log(err);
      return toastr.error(
        'Ocorreu algum erro inesperado.',
        'Por favor, tente novamente mais tarde.'
      );
    }
  };

  const handleQueryChange = (event) => {
    dispatch(change('PurchaseXmlImport', 'itemToAssociate', {}));

    const value = event.target.value;
    setQuery(value);
    debouncedSearchQuery(value);

    if (value.includes('Código:') && value.includes('Ref. Fab.:')) {
      products.forEach((p) => {
        const codeAndManufacturerRef = `Código: ${p.Code} - Descrição: ${
          p.Description
        } - Ref. Fab.: ${p.Manufacturer_Ref || 'Não informado'}`;

        if (codeAndManufacturerRef === value) {
          dispatch([
            change('PurchaseXmlImport', 'itemToAssociate.id', p.id),
            change('PurchaseXmlImport', 'itemToAssociate.Code', p.Code),
            change(
              'PurchaseXmlImport',
              'itemToAssociate.Description',
              p.Description
            ),
            change(
              'PurchaseXmlImport',
              'itemToAssociate.Multiplier_Base',
              p.Multiplier_Base || 1
            ),
            change('PurchaseXmlImport', 'itemToAssociate.brand', {
              label: p.Brands?.Description || 'Selecione',
              value: p?.Brand_id || '*',
            }),
            change(
              'PurchaseXmlImport',
              'itemToAssociate.Manufacturer_Ref',
              p.Manufacturer_Ref
            ),
            change(
              'PurchaseXmlImport',
              'itemToAssociate.Profit_Margin',
              p.Profit_Margin
            ),
            change(
              'PurchaseXmlImport',
              'itemToAssociate.Sale_Price',
              p.Sale_Price
            ),
          ]);
          debouncedSearchQuery('');
          setQuery('');
        }
      });
    }
  };

  const handleSubmit = async () => {
    if (!itemToAssociate?.id || !itemToAssociate?.Code)
      return toastr.warning('Informe o Produto');

    const checkItems = items.filter(
      (p) => p.productCode === itemToAssociate.Code
    );
    if (checkItems.length)
      return toastr.warning(
        'Produto já atrelado a essa compra, por favor escolha outro produto'
      );

    await setLoading(true);

    try {
      await providerRepository.createProductRelationship(providerInDB.id, {
        productId: itemToAssociate.id,
        productProviderCode: itemToEdit.providerCode,
      });

      const filterdItems = items.map((p) => {
        if (p.providerCode === itemToEdit.providerCode) {
          return {
            ...p,
            productId: itemToAssociate.id,
            productCode: itemToAssociate.Code,
            multiplierBase: itemToAssociate.Multiplier_Base,
            brand: itemToAssociate.brand,
            margin: itemToAssociate.Profit_Margin,
            newMargin: itemToAssociate.Profit_Margin,
            salesPrice: itemToAssociate.Sale_Price,
            newSalesPrice:
              p.unitValue + (itemToAssociate.Profit_Margin / 100) * p.unitValue,
          };
        }
        return p;
      });

      await dispatch([
        change('PurchaseXmlImport', 'items', filterdItems),
        change('PurchaseXmlImport', 'itemToAssociate', {}),
      ]);

      onSubmit();
    } catch (err) {
      console.log(err);
      return toastr.error(
        'Ocorreu algum erro inesperado.',
        'Por favor, tente novamente mais tarde.'
      );
    }

    setLoading(false);
  };

  return (
    <Modal
      show={true}
      onHide={() => (loading ? null : onHide())}
      dialogClassName="modal-import-associate-products modal-80w"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Editar Item</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body id="modal-import-associate-products-body">
        <Col xs={12} sm={12} md={12} lg={12} style={{ marginTop: '30px' }}>
          <Col xs={12} sm={12} md={12} lg={12} style={{ marginLeft: '0px' }}>
            <i
              id="iconepesquisar"
              style={{ cursor: 'pointer' }}
              className="fa fa-search"
            />
            <input
              type="text"
              style={{ paddingLeft: '30px' }}
              className="form-control foco-input"
              placeholder="Pesquisa por Código Interno, Descrição ou Referência do Fabricante"
              value={query}
              list="allProducts"
              onChange={handleQueryChange}
            />
            <datalist id="allProducts">
              {products.map((p) => (
                <option
                  key={p.id}
                  value={`Código: ${p.Code} - Descrição: ${
                    p.Description
                  } - Ref. Fab.: ${p.Manufacturer_Ref || 'Não informado'}`}
                />
              ))}
            </datalist>
          </Col>
          <div>
            <Col xs={12} sm={6} md={2} lg={2}>
              <Field
                label="Código Interno"
                name="itemToAssociate.Code"
                component={Input}
                disabled
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <Field
                label="Descrição"
                name="itemToAssociate.Description"
                component={Input}
                disabled
              />
            </Col>
            <Col xs={12} sm={6} md={2} lg={2}>
              <Field
                label="Ref. Fabricante"
                name="itemToAssociate.Manufacturer_Ref"
                component={Input}
                disabled
              />
            </Col>
            <Col xs={12} sm={6} md={2} lg={2}>
              <label>Cód. Fornecedor</label>
              <input
                className="form-control foco-input"
                value={itemToEdit?.providerCode}
                disabled
              />
            </Col>
          </div>
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="xml-import-products-buttons-modal">
            <button
              type="button"
              className="xml-import-button-danger"
              onClick={() => onCancel()}
              disabled={loading}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="xml-import-button-success"
              onClick={handleSubmit}
              disabled={loading}
            >
              Salvar{' '}
              {loading && <span className="fa fa-spinner fa-pulse fa-1x" />}
            </button>
          </div>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

export default AssociateProduct;
