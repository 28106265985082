import Home from '../views/home';
import Clients from '../views/Clients';
import ReportInvoicesByCustomer from '../views/InvoicesByCustomer';
import SalesByClient from '../views/SalesByClient';
import Advertisements from '../views/Advertisements';
import Advertising from '../views/Advertisements/Advertising';
import StoneLinks from '../views/StoneLinks';
import StoneLink from '../views/StoneLinks/StoneLink';
import Matera from '../views/Matera';
import MateraAccount from '../views/Matera/MateraAccount';
import TicketIssuanceHome from 'admin/views/TicketIssuance';
import BankBilletAccount from 'admin/views/TicketIssuance/BankBilletAccount';
import News from 'admin/views/News';
import NewsRegister from 'admin/views/News/NewsRegister';
import Polls from 'admin/views/Polls';
import VideoLinks from 'admin/views/VideoLinks';
import VideoLinkRegister from 'admin/views/VideoLinks/VideoLinkRegister';
import NpsRegister from 'admin/views/Polls/NPSRegister';
import SuccessReport from 'admin/views/SuccessReport';
import SystemUsageReport from 'admin/views/SystemUsageReport';
import BoletoFlexConfigurations from 'admin/views/BoletoFlex/BoletoFlexConfigurations';
import NFSeSettings from 'admin/views/NFSe/Settings';
import NFSePattern from 'admin/views/NFSe/Pattern';
import OperationTaxes from 'admin/views/NFSe/OperationTaxes';
import NFSeCitiesList from 'admin/views/NFSe/CitiesList';
import NFSePatternCities from 'admin/views/NFSe/PatternCities';
import { SuivCompany } from 'admin/views/SuivCompany';
import SuivCompanyForm from 'admin/views/SuivCompany/components/SuivCompanyForm/SuivCompanyForm';
import FinancingBankSlip from 'admin/views/FinancingBankSlip';
import FinancingBankSlipConfig from 'admin/views/FinancingBankSlip/NewFinancingBankSlip';
import { CRMBonusIndex } from 'admin/views/CRMBonus';
import NewCrmBonusForm from 'admin/views/CRMBonus/CrmBonusForm/NewCrmBonusForm';

export const adminRoutes = [
  {
    path: '/home',
    name: 'Início',
    active: 'home',
    Component: Home,
    layout: '/admin',
  },
  {
    path: '/clients',
    name: 'Clientes',
    active: 'clients',
    Component: Clients,
    layout: '/admin',
  },
  //#TODO - Refatorar relatórios
  // {
  //   path: '/invoice-by-customer',
  //   name: 'Relatório de Notas Fiscais por Cliente',
  //   active: 'reports',
  //   Component: ReportInvoicesByCustomer,
  //   layout: '/admin',
  // },
  // {
  //   path: '/sales-by-client',
  //   name: 'Vendas por Cliente',
  //   active: 'reports',
  //   Component: SalesByClient,
  //   layout: '/admin',
  // },
  // {
  //   path: '/success-points-report',
  //   name: 'Relatório de Pontos de Sucesso',
  //   active: 'reports',
  //   Component: SuccessReport,
  //   layout: '/admin',
  // },
  // {
  //   path: '/system-usage-report',
  //   name: 'Relatório de Uso de Sistema',
  //   active: 'reports',
  //   Component: SystemUsageReport,
  //   layout: '/admin',
  // },
  {
    path: '/advertisements',
    name: 'Propagandas',
    active: 'advertisements',
    Component: Advertisements,
    layout: '/admin',
  },
  {
    path: '/advertising',
    name: 'Nova Propaganda',
    active: 'advertisements',
    Component: Advertising,
    layout: '/admin',
  },
  {
    path: '/advertising#:id',
    name: 'Editar Propaganda',
    active: 'advertisements',
    Component: Advertising,
    layout: '/admin',
  },
  {
    path: '/stone-links',
    name: 'Stone Integração',
    active: 'stone-links',
    Component: StoneLinks,
    layout: '/admin',
  },
  {
    path: '/stone-link',
    name: 'Novo vínculo',
    active: 'stone-links',
    Component: StoneLink,
    layout: '/admin',
  },
  {
    path: '/stone-link#:id',
    name: 'Edtiar vínculo',
    active: 'stone-links',
    Component: StoneLink,
    layout: '/admin',
  },
  {
    path: '/matera',
    name: 'Conta Digital - Matera',
    active: 'matera',
    Component: Matera,
    layout: '/admin',
  },
  {
    path: '/matera-account',
    name: 'Nova Conta',
    active: 'matera',
    Component: MateraAccount,
    layout: '/admin',
  },
  {
    path: '/matera-account#:id',
    name: 'Editar Conta',
    active: 'matera',
    Component: MateraAccount,
    layout: '/admin',
  },
  {
    path: '/ticket-issuance',
    name: 'Boleto Bancário',
    active: 'ticket-issuance',
    Component: TicketIssuanceHome,
    layout: '/admin',
  },
  {
    path: '/bank-billet-account',
    name: 'Nova Carteira',
    active: 'ticket-issuance',
    Component: BankBilletAccount,
    layout: '/admin',
  },
  {
    path: '/bank-billet-account#:id',
    name: 'Editar Carteira',
    active: 'ticket-issuance',
    Component: BankBilletAccount,
    layout: '/admin',
  },
  {
    path: '/news',
    name: 'News',
    active: 'news',
    Component: News,
    layout: '/admin',
  },
  {
    path: '/news-register',
    name: 'Nova Notícia',
    active: 'news',
    Component: NewsRegister,
    layout: '/admin',
  },
  {
    path: '/news-update#:id',
    name: 'Editar Notícia',
    active: 'news',
    Component: NewsRegister,
    layout: '/admin',
  },
  {
    path: '/polls',
    name: 'Pesquisa',
    active: 'polls',
    Component: Polls,
    layout: '/admin',
  },
  {
    path: '/polls-register',
    name: 'Nova Pesquisa',
    active: 'polls',
    Component: NpsRegister,
    layout: '/admin',
  },

  {
    path: '/polls-update#:id',
    name: 'Editar Pesquisa',
    active: 'polls',
    Component: NpsRegister,
    layout: '/admin',
  },

  {
    path: '/videos',
    name: 'Videos',
    active: 'videos',
    Component: VideoLinks,
    layout: '/admin',
  },
  {
    path: '/videos-register',
    name: 'Novo Video',
    active: 'videos',
    Component: VideoLinkRegister,
    layout: '/admin',
  },
  {
    path: '/videos-update#:id',
    name: 'Editar Videos',
    active: 'videos',
    Component: VideoLinkRegister,
    layout: '/admin',
  },
  {
    path: '/financing-bank-slip',
    name: 'Boleto Parcelado',
    active: 'financing-bank-slip',
    Component: FinancingBankSlip,
    layout: '/admin',
  },
  {
    path: '/financing-bank-slip-config',
    name: 'Configuração Boleto Parcelado',
    active: 'financing-bank-slip',
    Component: FinancingBankSlipConfig,
    layout: '/admin',
  },
  {
    path: '/financing-bank-slip-config#:id',
    name: 'Editar Boleto Parcelado',
    active: 'boleto-flex',
    Component: FinancingBankSlipConfig,
    layout: '/admin',
  },
  {
    path: '/nfse-settings',
    name: 'Configurações de NFS-e',
    active: 'nfse',
    Component: NFSeSettings,
    layout: '/admin',
  },
  {
    path: '/nfse-pattern',
    name: 'Novo Padrão',
    active: 'nfse',
    Component: NFSePattern,
    layout: '/admin',
  },
  {
    path: '/nfse-pattern#:id',
    name: 'Editar Padrão',
    active: 'nfse',
    Component: NFSePattern,
    layout: '/admin',
  },
  {
    path: '/nfse-pattern-operation-taxes',
    name: 'Tributos e Operações',
    active: 'nfse',
    Component: OperationTaxes,
    layout: '/admin',
  },
  {
    path: '/nfse-cities-list',
    name: 'Listagem de Cidades',
    active: 'nfse',
    Component: NFSeCitiesList,
    layout: '/admin',
  },
  {
    path: '/nfse-pattern-cities',
    name: 'Cidades do Padrão',
    active: 'nfse',
    Component: NFSePatternCities,
    layout: '/admin',
  },
  {
    path: '/suiv',
    name: 'Suiv',
    active: 'suiv',
    Component: SuivCompany,
    layout: '/admin',
  },
  {
    path: '/suiv-register',
    name: 'Nova Empresa',
    active: 'suiv',
    Component: SuivCompanyForm,
    layout: '/admin',
  },
  {
    path: '/crm-bonus',
    name: 'CRM Bônus',
    active: 'crm-bonus',
    Component: CRMBonusIndex,
    layout: '/admin',
  },
  {
    path: '/crm-bonus-register',
    name: 'Novo CRM Bônus',
    active: 'crm-bonus',
    Component: NewCrmBonusForm,
    layout: '/admin',
  },
  {
    path: '/crm-bonus-register#:id',
    name: 'Editar CRM Bônus',
    active: 'crm-bonus',
    Component: NewCrmBonusForm,
    layout: '/admin',
  },
];
