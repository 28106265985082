import React from 'react';
import {
  Document,
  Text,
  Font,
  Page,
  StyleSheet,
  View,
} from '@react-pdf/renderer';
import { format } from 'date-fns';

import {
  cnpjMask,
  cpfMask,
  milhar,
  phoneMask,
  currency,
} from '../../../client/components/ToNormalize/ToNormalize';

import arialFont from '../../../assets/fonts/arial.ttf';
import arialFontBold from '../../../assets/fonts/arial-bold.ttf';
import fontRobotoItalic from '../../../assets/fonts/Roboto-Italic.ttf';

import constants from '../../../utils/constants';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { getDefaultText } from 'v2/helpers/sheetHelpers';

const TotalRow = ({ children, style, ...rest }) => (
  <View
    {...rest}
    style={[style, { flexDirection: 'row', justifyContent: 'space-between' }]}
  >
    {children}
  </View>
);

const ThermalCoil = ({
  sale,
  quickQuote,
  config,
  isProductionOrder,
  showPaymentSuggestions,
  companyBranch,
}) => {
  const company = sale.Company;
  const cpfCnpj =
    company.Cpf_Cnpj.length <= 11
      ? cpfMask(company.Cpf_Cnpj)
      : cnpjMask(company.Cpf_Cnpj);
  const isJuridica = company.Type === 'Jurídica';

  const companyAddress = company.Address[0];
  const companyPhones = company.Phones[0];
  const address = !companyAddress
    ? ''
    : `${companyAddress.Address}, ${companyAddress.Address_Number}`;
  const neighborhood = !companyAddress
    ? ''
    : `${companyAddress.Neighborhood}, ${companyAddress.City} / ${companyAddress.State}`;
  const phone = !companyPhones ? '' : phoneMask(companyPhones.Number_Phone1);

  const salesType = sale.SalesType.Description;
  const isOrcament = sale.SalesType_id === constants.SALES_TYPE_ID.ORCAMENT;
  const isOrderOfService =
    sale.SalesType_id === constants.SALES_TYPE_ID.ORDER_OF_SERVICE;
  const isOpen = sale.SaleStatus_id === constants.SALE_STATUS_ID.OPEN;

  const items = sale.SalesItems;

  const customer = {
    name: sale.Customer.Company_Name,
    street: !sale.Customer.Address[0]
      ? ''
      : sale.Customer.Address[0].Address +
        ' ' +
        sale.Customer.Address[0].Address_Number,
    neighborhood: !sale.Customer.Address[0]
      ? ''
      : sale.Customer.Address[0].Neighborhood,
    city: !sale.Customer.Address[0]
      ? ''
      : sale.Customer.Address[0].City + ' / ' + sale.Customer.Address[0].State,
  };

  const vehicle = {
    description: sale.Vehicle?.Model || '',
    licensePlate: sale.Vehicle?.License_Plate || '',
    color: sale.Vehicle?.Color || '',
    manufactureYear: sale.Vehicle?.Year_Manufacture || '',
    year: `${sale.Vehicle?.Year_Manufacture || ''}/${
      sale.Vehicle?.Year_Model || ''
    }`,
    kilometers: milhar(sale.Vehicle?.Kilometers) || '',
    Maintenance_Object: sale.Vehicle?.Maintenance_Object || '',
    serialIdentification: sale.Vehicle?.serialIdentification || '',
    vehicleTypeId: sale.Vehicle?.vehicleTypeId || '',
    brand: sale.Vehicle?.Brand || '',
    model: sale.Vehicle?.Model || '',
    rearGears: sale.Vehicle?.BicycleRearGears?.quantity || '',
    frontGears: sale.Vehicle?.BicycleFrontGears?.quantity || '',
    frameSize: sale.Vehicle?.BicycleFrameSize?.description || '',
    frameMaterial: sale.Vehicle?.BicycleFrameMaterial?.description || '',
    modality: sale.Vehicle?.BicycleModality?.description || '',
  };

  const payment = {
    paymentCondition: sale.Payments?.CondPayment?.Description || '',
    parcels:
      sale.Payments?.Parcels?.map((parcel) => ({
        id: parcel.id,
        dueDate: format(
          getDateOnlyFromDate(new Date(parcel.Due_Date)),
          'dd/MM/yyyy'
        ),
        paymentForm: parcel.FormOfPayment?.Desciption || '',
        value: currency(parcel.Amount),
      })) || [],
  };

  return (
    <Document>
      <Page
        size={{
          width: 226.77,
        }}
        style={styles.page}
      >
        <View style={{ textAlign: 'center', lineHeight: 1.3 }}>
          <Text style={{ fontWeight: 'bold' }}>
            {`${
              company.Trading_Name ? company.Trading_Name : company.Company_Name
            }`.toUpperCase()}
          </Text>
          {isJuridica ? (
            <Text>
              CNPJ:{cpfCnpj}
              {!!company.IE && ` IE:${company.IE}`}
            </Text>
          ) : (
            <Text>CPF: {cpfCnpj}</Text>
          )}
          <Text style={{ marginTop: 3 }}>{address}</Text>
          <Text>{neighborhood}</Text>
          <Text>Tel {phone}</Text>
          <Text>Email: {company.Email}</Text>
        </View>
        <LineBreak />
        <View
          style={[
            styles.padding2,
            {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            },
          ]}
        >
          <Text>
            {salesType}
            {!quickQuote && `: ${sale.Code}`}
          </Text>
          <Text>
            Data: {format(new Date(sale.Date), 'dd/MM/yyyy')}{' '}
            {format(new Date(sale.Date), 'HH:mm')}
          </Text>
        </View>
        <LineBreak />
        {customer.name ? (
          <>
            <View style={styles.padding5}>
              <Text style={{ fontWeight: 'bold' }}>
                Cliente: {customer.name}
              </Text>
              {!quickQuote && (
                <>
                  <Text>Endereço: {customer.street}</Text>
                  <Text>Bairro: {customer.neighborhood}</Text>
                  <Text>Cidade: {customer.city}</Text>
                </>
              )}
            </View>
            <LineBreak />
          </>
        ) : null}
        {/* VEICULO */}
        {!!sale.Vehicle &&
          ((!vehicle.licensePlate ||
            !vehicle.Maintenance_Object ||
            !vehicle.serialIdentification) &&
          !vehicle.description ? (
            <View />
          ) : (
            <>
              {vehicle?.vehicleTypeId !== 4 ? (
                <View style={styles.padding5}>
                  {vehicle.licensePlate || vehicle.Maintenance_Object ? (
                    <Text style={{ fontWeight: 'bold' }}>
                      Placa:{' '}
                      {vehicle.Maintenance_Object
                        ? vehicle.Maintenance_Object
                        : vehicle.licensePlate}
                    </Text>
                  ) : null}
                  {vehicle.description ? (
                    <Text>
                      {+companyBranch === 5
                        ? 'Item de Manutenção:'
                        : 'Veículo:'}{' '}
                      {vehicle.description}
                    </Text>
                  ) : null}
                  {!quickQuote && (
                    <>
                      <Text>Ano Fab./Ano Mod.: {vehicle.year}</Text>
                      <Text>Cor: {vehicle.color}</Text>
                      <Text>
                        KM Atual: {sale.Kilometers || vehicle.kilometers}
                      </Text>
                    </>
                  )}
                </View>
              ) : (
                <View style={styles.padding5}>
                  <Text style={{ fontWeight: 'bold' }}>
                    Série/Identific.: {vehicle.serialIdentification}
                  </Text>
                  <Text>
                    Marca/Modelo: {vehicle.brand} - {vehicle.model}
                  </Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                  >
                    <View style={{ width: '30%' }}>
                      <Text>Ano: {vehicle.year}</Text>
                    </View>
                    <View style={{ width: '30%' }}>
                      <Text>Aro: {vehicle.frameMaterial}</Text>
                    </View>
                    <View style={{ width: '40%' }}>
                      <Text>Tam.Quadro: {vehicle.frameSize}</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                  >
                    <View style={{ width: '50%' }}>
                      <Text>Modalidade: {vehicle.modality}</Text>
                    </View>
                    <View style={{ width: '50%' }}>
                      <Text>Cor: {vehicle.color}</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                  >
                    <View style={{ width: '50%' }}>
                      <Text>Marchas Dianteiras: {vehicle.frontGears}</Text>
                    </View>
                    <View style={{ width: '50%' }}>
                      <Text>Marchas Traseiras: {vehicle.rearGears}</Text>
                    </View>
                  </View>
                </View>
              )}

              <LineBreak />
            </>
          ))}
        {!isOrcament || (!isOrderOfService && !isOpen) ? (
          !quickQuote &&
          sale &&
          sale.updatedAt && (
            <>
              <View style={styles.padding5}>
                <Text>
                  Data da Venda:{' '}
                  {format(new Date(sale.updatedAt), 'dd/MM/yyyy HH:mm')}
                </Text>
              </View>
              <LineBreak />
            </>
          )
        ) : (
          <View />
        )}
        <View style={styles.padding2}>
          {/* HEADERS */}
          <View style={[styles.itemRow, { fontWeight: 'bold' }]}>
            <View style={{ width: '25%' }}>
              <Text>Código</Text>
            </View>
            <View style={{ width: '75%' }}>
              <Text>Item</Text>
            </View>
          </View>

          <View style={[styles.itemRow, { fontWeight: 'bold' }]}>
            <View style={{ width: '35%' }}>
              <Text>Quantidade</Text>
            </View>
            {!isProductionOrder ? (
              <>
                <View style={{ width: '35%' }}>
                  <Text>Preço Unit.</Text>
                </View>
                <View style={{ width: '30%' }}>
                  <Text>Preço Total</Text>
                </View>
              </>
            ) : (
              <View />
            )}
          </View>

          {/* HEADERS */}
          {items.map((item) => {
            const discountPercentage = (100 - item.Discount_Value) / 100;
            const unitPrice = item.Unit_Value * discountPercentage;
            return (
              <>
                <View style={styles.itemRow}>
                  <View style={{ width: '25%', textAlign: 'center' }}>
                    <Text>
                      {item.Products?.Code || item.Services?.Code || ''}
                    </Text>
                  </View>

                  <View style={{ width: '75%' }}>
                    <Text>{item.Description}</Text>
                  </View>
                </View>

                <View style={styles.itemRow}>
                  <View style={{ width: '25%', textAlign: 'center' }}>
                    <Text>{item.Quantity}</Text>
                  </View>
                  {!isProductionOrder ? (
                    <>
                      <View style={{ width: '55%' }}>
                        <Text>x {currency(unitPrice)} =</Text>
                      </View>
                      <View style={{ width: '20%' }}>
                        <Text>{currency(item.Amount)}</Text>
                      </View>
                    </>
                  ) : (
                    <View />
                  )}
                </View>
              </>
            );
          })}
        </View>
        <LineBreak />
        {(isOrderOfService || isOrcament) &&
        sale?.SalesEmployees?.length > 0 ? (
          <View style={[styles.padding5, { display: 'flex' }]}>
            <Text style={{ fontWeight: 'bold' }}>
              {+companyBranch === 5
                ? 'Técnicos Responsáveis:'
                : 'Mecânicos Responsáveis:'}
            </Text>
            {sale?.SalesEmployees.map((item) => (
              <Text>- {item.Employee.name}</Text>
            ))}
            <LineBreak />
          </View>
        ) : (
          <View />
        )}
        {!isProductionOrder ? (
          <>
            <View style={styles.padding5}>
              {!quickQuote && (
                <>
                  <TotalRow>
                    <Text>Subtotal:</Text>
                    <Text>{currency(sale.SubTotal)}</Text>
                  </TotalRow>

                  <TotalRow>
                    <Text>Desconto:</Text>
                    <Text>{currency(sale.Discount_Value)}</Text>
                  </TotalRow>
                </>
              )}

              {/* PARCELAS */}
              {sale.Payments && !isProductionOrder && (
                <div style={{ fontSize: '8px' }}>
                  <Text style={{ fontWeight: 'bold', marginTop: 15 }}>
                    {payment.paymentCondition}
                  </Text>
                  <View
                    style={{
                      flexDirection: 'row',
                      textAlign: 'center',
                      marginTop: 5,
                      marginBottom: 1,
                    }}
                  >
                    <View style={{ width: '25%' }}>
                      <Text style={[styles.span, styles.bold]}>Vencimento</Text>
                    </View>
                    <View style={{ width: '50%' }}>
                      <Text style={[styles.span, styles.bold]}>
                        Forma de Pagamento
                      </Text>
                    </View>
                    <View style={{ width: '25%' }}>
                      <Text style={[styles.span, styles.bold]}>Valor</Text>
                    </View>
                  </View>
                  {payment.parcels.map((parcel) => (
                    <View
                      key={parcel.id}
                      style={{ flexDirection: 'row', textAlign: 'center' }}
                    >
                      <View style={{ width: '25%' }}>
                        <Text style={styles.span}>{parcel.dueDate}</Text>
                      </View>
                      <View style={{ width: '50%' }}>
                        <Text style={styles.span}>{parcel.paymentForm}</Text>
                      </View>
                      <View style={{ width: '25%' }}>
                        <Text style={styles.span}>{parcel.value}</Text>
                      </View>
                    </View>
                  ))}
                </div>
              )}

              <View style={{ marginTop: 10 }}>
                {sale.freightValue > 0 && (
                  <TotalRow>
                    <Text>Frete:</Text>
                    <Text>{currency(sale.freightValue)}</Text>
                  </TotalRow>
                )}

                <TotalRow>
                  <Text>Total:</Text>
                  <Text>{currency(sale.Final_Value)}</Text>
                </TotalRow>
              </View>

              {!quickQuote && (
                <>
                  <TotalRow>
                    <Text>Total Pago:</Text>
                    <Text>{currency(sale.totalPaid)}</Text>
                  </TotalRow>
                  {!!sale.totalPaid && sale.totalPaid > sale.Final_Value ? (
                    <TotalRow>
                      <Text>Troco:</Text>
                      <Text>{currency(sale.cashBack)}</Text>
                    </TotalRow>
                  ) : (
                    <View />
                  )}
                </>
              )}
              <Text
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  marginTop: 10,
                }}
              >
                Esse cupom não é um documento fiscal
              </Text>
            </View>
            <LineBreak />
          </>
        ) : (
          <View />
        )}

        {!sale.Payments &&
        sale?.paymentSuggestions?.length &&
        showPaymentSuggestions ? (
          <View>
            <Text
              style={{
                marginTop: '5px',
                marginBottom: '5px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {'Sugestão de Pagamento'}
            </Text>
            {sale.paymentSuggestions.map((paymentSuggestion) => (
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={{ width: '100%' }}>
                  <Text style={{ fontSize: 9 }}>{paymentSuggestion}</Text>
                </View>
              </View>
            ))}
          </View>
        ) : (
          <View />
        )}

        {(!!sale.Company.defaultSalesOrderFooterText ||
          !!sale.Company.defaultOrcamentFooterText) && (
          <>
            <View style={styles.padding5}>
              <Text style={styles.span}>
                {getDefaultText(
                  !!quickQuote
                    ? 'Orçamento Rápido'
                    : sale.SalesType.Description,
                  sale
                )}
              </Text>
            </View>
            <LineBreak />
          </>
        )}
        {/* Assinatura */}
        {sale.SalesType.Description === 'Ordem de Serviço' &&
        config.showSheetSignatureField === 1 &&
        !isProductionOrder ? (
          <View
            style={{
              padding: 5,
              marginTop: 10,
            }}
          >
            <View style={{ flexDirection: 'column' }}>
              <View>
                <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                  Autorização do Cliente
                </Text>
              </View>
              <View style={{ marginTop: 8 }}>
                <Text style={{ fontSize: 9 }}>
                  Declaro, através dessa Ordem de Serviço, nº{sale.Code}, que
                  autorizo a execução do serviços apresentados no{' '}
                  {+companyBranch === 5 ? 'item de manutenção' : 'veículo'} em
                  questão.
                </Text>
              </View>
              <View
                style={{
                  marginTop: 8,
                }}
              >
                <Text style={{ fontSize: 10, fontStyle: 'italic' }}>
                  {companyAddress.City}, ____ /____ /____
                </Text>
                <Text
                  style={{ fontSize: 10, marginTop: 4, fontStyle: 'italic' }}
                >
                  Ass. do cliente:________________________
                </Text>
              </View>
            </View>
          </View>
        ) : (
          <View />
        )}
      </Page>
    </Document>
  );
};

const LineBreak = () => (
  <View
    style={{
      marginTop: 5,
      marginBottom: 5,
      borderBottomStyle: 'dashed',
      borderBottomWidth: 1,
    }}
  />
);

Font.register({
  family: 'Arial',
  fonts: [
    { src: arialFont },
    { src: arialFontBold, fontWeight: 'bold' },
    { src: fontRobotoItalic, fontStyle: 'italic' },
  ],
});

const styles = StyleSheet.create({
  padding2: {
    paddingLeft: 2,
    paddingRight: 2,
  },
  padding5: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  itemRow: {
    flexDirection: 'row',
    width: '100%',
  },
  page: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 9,
    fontFamily: 'Arial',
  },
  span: {
    fontSize: 8,
  },
  bold: {
    fontWeight: 'bold',
  },
});

export default ThermalCoil;
